<template>
    <div style="height: calc(-50px + 100vh); overflow-y: scroll;">
        <iframe
            scrolling="auto"
            frameborder="0"
            id="iframe"
            style="height: calc(100% - 1.5rem); width: 100%;"
            :src="kefu_url"
        ></iframe>
    </div>
</template>

<script>

export default {
    name: 'activity',
    data() {
      return {
          kefu_url:"https://138688.cc//m"
      }
    },
    created() {
        let that = this;
        that.$apiFun.post('/api/getservicerurl', {}).then(res => {
            if (res.code != 200) {
                that.showTost(0, res.message);
            }
            if (res.code == 200) {
                //that.kefu_url = res.data.url;
            }
        });
    }
}
</script>

<style scoped>
</style>
