<template>
    <div style="width: 100%; min-height: 100vh; background:rgb(235, 228, 198, 0.18);">
        <van-nav-bar
            title="卡片管理"
            left-arrow
            fixed
            :style="{ backgroundColor: 'rgb(237, 242, 253)' }"
            @click-left="onClickLeft"
        />
        <div style="height: 46px;"></div>
        <van-tabs v-model="activeTab">
            <van-tab title="虚拟币">
                <div v-for="(card, index) in usdtCards" :key="index" class="lis">
                    <img src="/static/image/1595237922936176.png" alt="" class="lefs">
                    <div class="cest">
                        <div class="type">{{ card.bank_owner }}</div>
                        <div class="num">
                            <span v-for="(part, partIndex) in card.bank_no" :key="partIndex">{{ part }}</span>
                        </div>
                    </div>
                    <img src="/static/style/wdb_icon.png" alt="" class="rigss" @click="delcard(card.id)">
                </div>
                <div class="adds">
                    <van-button type="info" plain block @click="addUSDTAddress" v-if="usdtCards.length<5">
                        添加USDT地址
                    </van-button>
                    <div class="btntits">最多支持添加5个地址</div>
                </div>
            </van-tab>
            <van-tab title="银行卡">
                <div v-for="(card, index) in bankCards" :key="index" class="lis">
                    <img :src="'/uploads/bank_icons/'+card.bank+'.png'" alt="" class="lefs">
                    <div class="cest">
                        <div class="type">{{ card.bank }}</div>
                        <div class="type">{{ card.bank_owner }}</div>
                        <div class="num">
                            <span>****</span>
                            <span>****</span>
                            <span>****</span>
                            <span>{{ card.bank_no.slice(-4) }}</span>
                        </div>
                    </div>
                    <img src="/static/style/wdb_icon.png" alt="" class="rigss" @click="delcard(card.id)">
                </div>
                <div class="adds">
                    <van-button type="info" plain block @click="addBankCard" v-if="bankCards.length<5">
                        添加银行卡
                    </van-button>
                    <div class="btntits">最多支持添加5张银行卡</div>
                </div>
            </van-tab>
            <van-tab title="钱能">
                <div v-for="(card, index) in qnCards" :key="index" class="lis">
                    <img src="/static/image/qnpay.png" alt="" class="lefs">
                    <div class="cest">
                        <div class="num">
                            <span v-for="(part, partIndex) in card.bank_no" :key="partIndex">{{ part }}</span>
                        </div>
                    </div>
                    <img src="/static/style/wdb_icon.png" alt="" class="rigss" @click="delcard(card.id)">
                </div>
                <div class="adds">
                    <van-button type="info" plain block @click="addQnAddress" v-if="bankCards.length<5">
                        添加钱能钱包地址
                    </van-button>
                    <div class="btntits">最多支持添加5个地址</div>
                </div>
            </van-tab>
            <van-tab title="波币">
                <div v-for="(card, index) in bbCards" :key="index" class="lis">
                    <img src="/static/image/iconBbpay@3x.png" alt="" class="lefs">
                    <div class="cest">
                        <div class="num">
                            <span v-for="(part, partIndex) in card.bank_no" :key="partIndex">{{ part }}</span>
                        </div>
                    </div>
                    <img src="/static/style/wdb_icon.png" alt="" class="rigss" @click="delcard(card.id)">
                </div>
                <div class="adds">
                    <van-button type="info" plain block @click="addBbAddress" v-if="bankCards.length<5">
                        添加波币钱包地址
                    </van-button>
                    <div class="btntits">最多支持添加5个地址</div>
                </div>
            </van-tab>
            <van-tab title="K豆">
                <div v-for="(card, index) in kdCards" :key="index" class="lis">
                    <img src="/static/image/kdpay.png" alt="" class="lefs">
                    <div class="cest">
                        <div class="num">
                            <span v-for="(part, partIndex) in card.bank_no" :key="partIndex">{{ part }}</span>
                        </div>
                    </div>
                    <img src="/static/style/wdb_icon.png" alt="" class="rigss" @click="delcard(card.id)">
                </div>
                <div class="adds">
                    <van-button type="info" plain block @click="addKdAddress" v-if="bankCards.length<5">
                        添加K豆钱包地址
                    </van-button>
                    <div class="btntits">最多支持添加5个地址</div>
                </div>
            </van-tab>
            <van-tab title="JD钱包">
                <div v-for="(card, index) in jdCards" :key="index" class="lis">
                    <img src="/static/image/jdpay.png" alt="" class="lefs">
                    <div class="cest">
                        <div class="num">
                            <span v-for="(part, partIndex) in card.bank_no" :key="partIndex">{{ part }}</span>
                        </div>
                    </div>
                    <img src="/static/style/wdb_icon.png" alt="" class="rigss" @click="delcard(card.id)">
                </div>
                <div class="adds">
                    <van-button type="info" plain block @click="addJdAddress" v-if="bankCards.length<5">
                        添加JD钱包地址
                    </van-button>
                    <div class="btntits">最多支持添加5个地址</div>
                </div>
            </van-tab>
            <van-tab title="C币钱包">
                <div v-for="(card, index) in cbCards" :key="index" class="lis">
                    <img src="/static/image/cbpay.png" alt="" class="lefs">
                    <div class="cest">
                        <div class="num">
                            <span v-for="(part, partIndex) in card.bank_no" :key="partIndex">{{ part }}</span>
                        </div>
                    </div>
                    <img src="/static/style/wdb_icon.png" alt="" class="rigss" @click="delcard(card.id)">
                </div>
                <div class="adds">
                    <van-button type="info" plain block @click="addCbAddress" v-if="bankCards.length<5">
                        添加C币钱包地址
                    </van-button>
                    <div class="btntits">最多支持添加5个地址</div>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import { NavBar, Tab, Tabs, Button } from 'vant';

export default {
    components: {
        [NavBar.name]: NavBar,
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
        [Button.name]: Button,
    },
    data() {
        return {
            activeTab: 0,
            usdtCards: [],
            bankCards: [],
            qnCards:[],
            bbCards:[],
            kdCards:[],
            jdCards:[],
            cbCards:[],
        };
    },
    created() {
        this.getuseCardlist()
    },
    methods: {
        getuseCardlist() {
            let that = this;
            that.$apiFun.post('/api/getcard', { type: 9 }).then(res => {
                if (res.code == 200) {
                    that.cbCards = res.data;
                }
            });
            that.$apiFun.post('/api/getcard', { type: 8 }).then(res => {
                if (res.code == 200) {
                    that.jdCards = res.data;
                }
            });
            that.$apiFun.post('/api/getcard', { type: 7 }).then(res => {
                if (res.code == 200) {
                    that.kdCards = res.data;
                }
            });
            that.$apiFun.post('/api/getcard', { type: 6 }).then(res => {
                if (res.code == 200) {
                    that.bbCards = res.data;
                }
            });
            that.$apiFun.post('/api/getcard', { type: 5 }).then(res => {
                if (res.code == 200) {
                    that.qnCards = res.data;
                }
            });
            that.$apiFun.post('/api/getcard', { type: 2 }).then(res => {
                if (res.code == 200) {
                    that.usdtCards = res.data;
                }
            });
            that.$apiFun.post('/api/getcard', { type: 1 }).then(res => {
                if (res.code == 200) {
                    that.bankCards = res.data;
                }
            });
        },
        delcard(id){
            let that = this;
            that.$dialog
                .confirm({
                    title: '温馨提示',
                    message: '确定要解除该绑定卡片吗?',
                })
                .then(() => {
                    that.$parent.showLoading();

                    that.$apiFun
                        .post('/api/delcard', {id:id})
                        .then(res => {
                            that.$parent.closeDaoTime();
                            that.$parent.hideLoading();
                            if (res.code == 200) {
                                that.$parent.showTost(1, '解绑成功');
                                that.getuseCardlist()
                            }else{
                                that.$parent.showTost(0, '系统错误');
                            }
                        })
                })
        },
        onClickLeft() {
            this.$router.go(-1);
        },
        addUSDTAddress() {
            this.$router.push("/addUsdtCard");
        },
        addBankCard() {
            this.$router.push("/addBankCard");
        },
        addQnAddress(){
            this.$router.push("/addQnCard");
        },
        addBbAddress(){
            this.$router.push("/addBbCard");
        },
        addKdAddress(){
            this.$router.push("/addKdCard");
        },
        addJdAddress(){
            this.$router.push("/addJdCard");
        },
        addCbAddress(){
            this.$router.push("/addCbCard");
        }
    },
};
</script>

<style scoped>
* , .van-tab__text, .van-tab__text--ellipsis{
    font-size: 12px;
}
.bsd {
    width: 90%;
    background: #fdfdfd;
    border-radius: 10px;
    padding: 20px 10px;
    margin: 10px auto 0
}

.topsf {
    background-size: 100% 100%;
    height: 180px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to right, #8B4513, #FFD700);
  /* padding: 20px; */
    border-radius: 10px;
}

.topsf .tois {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid #fbfaf5;
    margin: 0 auto;
    width: 90%;
    color: #fbfaf5
}

.topsf .tois .tisaa {
    font-size: 16px;
    font-weight: 700
}

.topsf .tois .newsa {
    margin-top: 6px;
    font-size: 10px
}

.topsf .tois img {
    width: 50px;
    margin-right: 15px
}

.bosfs {
    border-radius: 15px;
    padding: 4px 12px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 90%;
    margin: 15px auto 0;
    background-size: 100% 100%;
    -webkit-box-shadow: 0 .04rem .2rem 0 rgba(93,114,162,.11);
    box-shadow: 0 .04rem .2rem 0 rgba(93,114,162,.11)
}

.bosfs .hgsw {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 1px solid #f5f0f0
}

.bosfs .hgsw .firsimg {
    width: 24px
}

.bosfs .hgsw .tit {
    color:#909090;
    margin-left: 10px
}

.bosfs .hgsw .tisf {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 12px;
    color: #a2aec8;
    text-align: right
}

.bosfs .hgsw .rigiong {
    width: 6px
}

.topsf {
    background-size: 100% 100%;
    height: 180px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to right, #8B4513, #FFD700);
  /* padding: 20px; */
    border-radius: 10px;
}

.topsf .tois {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid #fbfaf5;
    margin: 0 auto;
    width: 90%;
    color: #fbfaf5
}

.topsf .tois .tisaa {
    font-size: 16px;
    font-weight: 700
}

.topsf .tois .newsa {
    margin-top: 6px;
    font-size: 10px
}

.topsf .tois img {
    width: 50px;
    margin-right: 15px
}

.bosfs {
    border-radius: 15px;
    padding: 4px 12px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 90%;
    margin: 15px auto 0;
    background-size: 100% 100%;
    -webkit-box-shadow: 0 .04rem .2rem 0 rgba(93,114,162,.11);
    box-shadow: 0 .04rem .2rem 0 rgba(93,114,162,.11)
}

.bosfs .hgsw {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 1px solid #f5f0f0
}

.bosfs .hgsw .firsimg {
    width: 24px
}

.bosfs .hgsw .tit {
    color:#909090;
    margin-left: 10px
}

.bosfs .hgsw .tisf {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 12px;
    color: #a2aec8;
    text-align: right
}

.bosfs .hgsw .rigiong {
    width: 6px
}

.van-tabs__nav--card .van-tab.van-tab--active {
    color: #fbfaf5;
    background-color: #727272
}

.van-tabs__nav--card {
    border: 1px solid #727272
}

.van-tabs__nav--card .van-tab {
    color: #727272;
    border-right: 0 solid #727272
}

.saibox {
    -ms-flex-pack: distribute;
    justify-content: space-around;
    height: 1.1rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 12px
}

.saibox .sai {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.saibox .sai {
    height: .8rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 40%;
    background: #f2f2f2;
    border-radius: 1.1rem;
    font-size: .3rem
}

.lisg {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.lisg .bs {
    width: 25%;
    height: 1.1rem
}

.lisg .bs .lisga {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.lisg .bs .lisga {
    width: calc(100% - 8px);
    height: .9rem;
    border: .02rem solid #cbced8;
    border-radius: .08rem;
    color: #a5a9b3;
    font-size: .2rem;
    text-align: center
}

.lisg .bs .lisga.act {
    background: #727272;
    color: #fbfaf5;
    border: none
}

.usrse {
    margin-top: 10px;
    background: #fbfaf5;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px
}

.usrse .nams {
    font-size: .38rem;
    color: #000;
    vertical-align: middle;
    margin-top: 10px
}

.usrse .imgsa {
    position: relative;
    height: 2rem;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: .2rem
}

.usrse .imgsa .bisn {
    width: .8rem;
    position: absolute;
    bottom: .3rem;
    left: 1.4rem
}

.usrse .imgsa img {
    width: 2rem;
    border-radius: 50%
}[class*=van-hairline]:after {
     border: none
 }

.tops {
    background: url(/static/image/safety_back.577bcf768c07205d5e3e2c7949d5677c.577bcf76.png) no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 5rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 30px 0;
    min-height: 200px
}

.tops img {
    width: 3rem;
    display: block;
    margin: 0 auto
}

.tops .tes {
    color: #fbfaf5;
    font-size: .3rem;
    text-align: center;
    padding-top: 15px
}

.boxst {
    width: 90%;
    background: #fbfaf5;
    border-radius: 20px;
    position: relative;
    margin: -10px auto 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px
}

.boxst .hgs {
    border-bottom: 1px solid #f1f1f1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 1.3rem;
    min-height: 50px
}

.boxst .hgs .lfs {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.boxst .hgs .lfs .topas {
    font-size: .3rem
}

.boxst .hgs .lfs .tisg {
    margin-top: 4px;
    color: #999;
    font-size: .3rem
}

.boxst .hgs .rigs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #999;
    font-size: .3rem
}

.boxst .hgs .rigs img {
    width: .34rem;
    margin-left: 4px
}

.adds {
    width: 60%;
    margin: 100px auto 0
}

.adds .btntits {
    text-align: center;
    margin-top: 20px;
    font-size: .28rem;
    color: #6c6e82
}

.lis {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 95%;
    margin: 20px auto 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px 25px;
    min-height: 80px;
    border-radius: 10px;
    position: relative;
    background: #fbfaf5
}

.lis .lefs {
    width: 1.5rem
}

.lis .cest {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 20px
}

.lis .cest .type {
    font-size: .4rem;
    font-weight: 700;
    color: #98a8c5;
    margin-top: 6px
}

.lis .cest .num {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 6px;
    color: #98a8c5
}

.lis .cest .num span {
    font-size: .6rem
}

.lis .rigss {
    position: absolute;
    top: 10px;
    right: 10px;
    width: .6rem
}

.tipsh {
    width: 95%;
    margin: 6px auto;
    border-radius: 10px;
    background: #f8f8f8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 6px
}

.tipsh .tops {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: .4rem;
    font-weight: 700;
    color: #333;
    height: 1rem
}

.tipsh .tops span {
    font-size: .29rem;
    font-weight: 400
}

.tipsh .tsg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.tipsh .tsg .tsgs {
    height: .56rem;
    line-height: .56rem;
    color: #a5a9b3;
    font-size: .2rem;
    text-align: center;
    padding: 4px 8px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-image: url(data:image/png;
    base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAAA0CAYAAADPCHf8AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALCSURBVHgB7d0hcNtAEIXhtWh4y8vDixseXHPz4ibYKW2Kw1UsHt7g8vCWB7u7mYsnss7S3elmam3+b8bjxM6Evaz1tFJWu91uKyLnAixbt1qt7qSyRh+3Aizfpf6xv5TKGk3dX31uBVi+jYak6qehJjx3+ngSYPmuNCTvpZLngOgUsXBU//wG/Adn+thqSM6kgpcJYiG516ffAizfO31cSQXNwfc/BfDhXKfIRmbqBUSniE2QewF8sGZrLTM0kdfsWIQDdnix1pB8lEKDgIQD9k4AP75oSD5IgdgEsZDYeZE/AvhgjVZR/duMvPdDAD+s2fqaW/8eDUg4YKf2hSf2MSur2Wom3mdPC95c5DRbowFhTwtOWbN1kfKDUxPEsKcFjzYpzdZkQNjTglNJzVbKBGFPC15NNltJAQnY04JHo81WckDY04JjR5utnAli2NOCV+vYJbtZAWFPC84Nmq3cCWIsIOxpwavt62YrOyBhirCnBa96l+yWTBD2tODd/pLdlRTShNkv4QQiPOuKJgjwRjzNCcisa32BE/dgFw4WBSRsQiZtQwILZFvs3+2L0gnyWQCfLBzXoa3ND0g4JV/t1o7AibnRcOzP82UFJDRX1e+gDZyIOw3H4+sXcieITY8q9zwFTkyr4RisUSUHJEwPDszh0UO41dVAzgTZCuDPvrGKSQpIqHU5MIc3vcYqZjIg4aMVtS68sVD0GquYlAlirRXTA960h41VzGhAqHXhVLSxipmaIOxbwZvuWGMVczQg7FvBocfc/6U+NkE4MIcn1lh9k0zRgLBvBWessbqeaqxiBgEJB+afBPDjtiQcJjZBmB7wxBqrX1KoFxD2reBMVmMVczhB2LeCF9mNVcw+IOxbwZGixirmOSDsW8GR4sYq5mWCMD3gxU2tcJgmTA9WSuBBG+76WY1NEMIBD9q5jVXMPzyG0oj5jr9QAAAAAElFTkSuQmCC);background-size: cover
}

.usrse {
    background: #fbfaf5;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px
}

.usrse .nams {
    font-size: .38rem;
    color: #000;
    vertical-align: middle;
    margin-top: 10px;
    font-weight: 700
}

.usrse .imgsa {
    position: relative;
    height: 2rem;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: .2rem
}

.usrse .imgsa .bisn {
    width: .8rem;
    position: absolute;
    bottom: .3rem;
    left: 1.4rem
}

.usrse .imgsa img {
    width: 2rem;
    border-radius: 50%
}[class*=van-hairline]:after {
     border: none
 }

.van-tab, .van-tab__text, .van-tab__text--ellipsis{
    font-size: 12px !important;
}
:deep(.van-tabs .van-tab),
:deep(.van-tabs .van-tab__text),
:deep(.van-tabs .van-tab__text--ellipsis) {
    font-size: 12px !important;
}

</style>


